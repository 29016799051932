
import React, {useState, useEffect} from "react";
import axios from "axios";
import LoadingBar from 'react-top-loading-bar';


axios.defaults.baseURL='https://angitia.co.in/angitialara/api/';


function GalleryContent() {
    const [progress,setProgress]=useState(0);

    const [galleryarr, setGalleryarr] = useState([]);
    async function getgallery() {
        setProgress(100);

        var resp=await axios.get("/portfolio");
        var data=resp.data;
        setGalleryarr(data.obj);
     }

     let imgpath="https://angitia.co.in/angitialara/upload_portfolio/";

useEffect(() => {
    getgallery();
}, [])

   
            
    return(
        <>

        <LoadingBar
            color='#0e00d1'
            progress={progress}
            transitionTime={2000}
            loaderSpeed={1000}
            waitingTime={2000}
            onLoaderFinished={()=>setProgress(0)}
            className="lodaerPosition"
        />


<div className="py-4 midWrapper">
<section className="py-5 gallery-row">
    <div className="container">
        <div className="row">

        {galleryarr.map((e)=>

            <div className="col-md-4" key={e.slider_id}>
                <div className='galleryBox'><img src={imgpath+e.portfolio_photo} alt="" className="img-fluid" /></div>
            </div>
        )}



           

            {/* <div className="col-md-4">
                <div className='galleryBox'><img src="images/OurVision.jpg" alt="" className="img-fluid" /></div>
            </div>
            <div className="col-md-4">
            <div className='galleryBox'><img src="images/OurVision.jpg" alt="" className="img-fluid" /></div>
            </div>           */}
            
        </div>

 
       




        </div>
</section>
</div>


        </>
    );
}

export default GalleryContent;