
// import React  from 'react';

import React, {useState, useEffect} from "react";
import {useParams, Link} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';

import axios from "axios";
import parse from 'html-react-parser';
import {useNavigate} from "react-router-dom";

axios.defaults.baseURL='https://angitia.co.in/angitialara/api/';

function ProductsContent() {
        const [progress,setProgress]=useState(0);

        let navigate=useNavigate();

        const rootimg="https://angitia.co.in/angitialara/upload_product/";
   
        const [productlisting, setProductlisting] = useState([]);
        const [childlisting, setChildlisting] = useState([]);

        let prmid=useParams();
       

        async function getproductlisting() {
            setProgress(100);

            var fd = new FormData();
            fd.append("parentid", prmid.parentid);
            var resp=await axios.post("/getproduct",fd);
            var data=resp.data;
            setProductlisting(data);

            // console.log(data);      
         }

         async function getchildlist() {
            setProgress(100);

            var fd = new FormData();
            fd.append("parentid", prmid.parentid);
            var resp=await axios.post("/getchildcat",fd);
            var data=resp.data;
            setChildlisting(data);

            console.log(data);      
         }

         
    
         
        useEffect(() => {
            getproductlisting();
            getchildlist();
        }, [prmid.parentid])

            
    return(
        <>


        <LoadingBar
            color='#0e00d1'
            progress={progress}
            transitionTime={2000}
            loaderSpeed={1000}
            waitingTime={2000}
            onLoaderFinished={()=>setProgress(0)}
            className="lodaerPosition"
        />



<div className="py-4 midWrapper">



<section className="py-5 welcome-row">
    <div className="container">
        <div className="row">
        <div className="col-md-9 pb-3">
            <p>New product innovation in arthroscopic surgery is the heart and soul of Biotek, which has resulted in the development of wide range of products for arthroscopic and minimally invasive orthopaedic surgical procedures. BIOTEK offers comprehensive range of products of sports medicine.</p>
        </div>

        <div className="col-md-9">
            <div className='row pb-3'>
                {productlisting.map((e)=>
                    <div className="col-md-4 pb-3" key={e.id}>
                        <img src={rootimg+e.product_image} alt="" class="img-fluid border" style={{cursor:'pointer'}} onClick={()=>{
                            let path=`/productdetails/`+e.product_name+ `/`+e.id;
                            navigate(path);
                        }} />
                        <h5 className='py-2'>
                        <Link to={'/productdetails/'+e.product_name+ '/'+e.id}>{e.product_name}</Link>
                        </h5>
                    </div>
                )}               
                
            </div>


            
            
       
        </div>

        <div className="col-md-3">
            <div className='card'>
            <div class="card-header bg-primary text-light fw-bold">
            {prmid.catname}
            </div>
               
            <div class="list-group">
                {/* <a href="#" class="list-group-item list-group-item-action">A simple default list group item</a> */}
                {childlisting.map((e)=>
                    <div key={e.cat_id}>
                    <Link to="#" className="list-group-item list-group-item-action" onClick={async()=>{
                        var fd = new FormData();
                        fd.append("parentid", prmid.parentid);
                        fd.append("catid", e.cat_id);
                        var resp=await axios.post("/getproduct",fd);
                        var data=resp.data;
                        setProductlisting(data);
                    }}><i className="fa fa-angle-right" aria-hidden="true"></i> {e.cat_name}</Link>
                    </div>
                )}
                    <Link to="#" className="list-group-item list-group-item-action" onClick={getproductlisting}><i className="fa fa-angle-right" aria-hidden="true"></i> VIEW ALL</Link>

            </div>
            </div>
        </div>




           
          
            
        </div>
       
    



        </div>
</section>









</div>

        </>
    );
}

export default ProductsContent;