import React, {useState, useEffect} from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import axios from "axios";

axios.defaults.baseURL='https://angitia.co.in/angitialara/api/';




function Banner() {

    const [homebannerarr, setHomebannerarr] = useState([]);

    async function gethomebanner() {
        var resp=await axios.get("/sliderlist");
        var data=resp.data;
        setHomebannerarr(data.obj);

        console.log(data.obj);
     }

     let imgpath="https://angitia.co.in/angitialara/upload_slider/";

useEffect(() => {
    gethomebanner();
}, [])




    const homeBanner = {

        

        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };



    return(
      <>

<header className="bg_header headerspace">
<div className='homeBannerWrapper'>
<Carousel responsive={homeBanner} arrows={false} showDots={true} autoPlay={true} autoPlaySpeed={1500}>

        {homebannerarr.map((e)=>
            <div key={e.slider_id}>
            <img src= {imgpath+e.slider_image} alt="" />
            <div className="banner_text_wrap">
                  <div className="container">
                      <div className="row">
                          <div className="col-md-12">
                              <div className="banner_text_area">
                                  <h2>{e.slider_name}</h2>
                                  <p className="fs-4">{e.slider_desc}</p>
                                  <p className="btn-know-more"><a href="./contact-us" className="btn btn-primary rounded btn-lg">Know more</a></p>
                              </div>
                          </div>
                      </div>
                    </div>
            </div>  
          </div>
        )}



  {/* <div>
      <img src="images/banner1.png" alt="" />
      <div className="banner_text_wrap">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="banner_text_area">
                            <h2>Complete Health Care Solution for Everyone</h2>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor Lorem ipsum dolor sit amet consetetur sadipscing elitr</p>
                            <p className="btn-know-more"><a href="#" className="btn btn-primary rounded btn-lg">Know more</a></p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>  
    </div> */}

</Carousel>
</div>
</header> 

      </>
  );
}

export default Banner;