import React, { Component, useState, useEffect }  from 'react';
import {Link} from "react-router-dom";
// import React, {Component, useState, useEffect} from "react";

const BannerInner = (props) => {
// function BannerInner  () {

    const [pagetitle, setPagetitle] = useState("");

    function getbannertitle () {

        if (window.location.pathname=='/about-us') {
            setPagetitle("ABOUT US");
        }
        if (window.location.pathname=='/gallery') {
            setPagetitle("Gallery");
        }

        if (window.location.pathname=='/members') {
            setPagetitle("Members");
        }

        if (window.location.pathname=='/contact-us') {
            setPagetitle("Contact Us");
        }

        if (window.location.pathname=='/blog') {
            setPagetitle("Blog");
        }


        

    }

    useEffect(() => {
        getbannertitle();
    }, [])

  return(
      <>
      
      <header className="bg_header headerspace header_inner">
 

    <div className="banner_text_wrap">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="banner_text_area">

                        <h2>{pagetitle} </h2>
                        <p><Link to="/">Home</Link> : {pagetitle} </p>
                       
                    </div>
                </div>
            </div>
            
        </div>
    </div> 
   
    
</header>

      </>
  );
}

export default BannerInner;