
import React, {useState, useEffect} from "react";
import Testimonial from '../../src/inc/Testimonials';
import LoadingBar from 'react-top-loading-bar';

import axios from "axios";
import parse from 'html-react-parser';
axios.defaults.baseURL='https://angitia.co.in/angitialara/api/';



function IndexContent() {

    const [progress,setProgress]=useState(0);
    const [welcomecontent, setWelcomecontent] = useState("");
    const [abouthome,setAbouthome]=useState("");

    async function getwelcomecontent() {
        setProgress(100);

        var resp=await axios.get("/welcome");
        var data=resp.data;
        setWelcomecontent(data.welcome_content);
        setAbouthome(data.home_about);

        console.log(data.welcome_content);
     }

     
     
    useEffect(() => {
        getwelcomecontent();
    }, [])


    return(
        <>

        

        <LoadingBar
        color='#0e00d1'
        progress={progress}
        transitionTime={2000}
        loaderSpeed={1000}
        waitingTime={2000}
        onLoaderFinished={()=>setProgress(0)}
        className="lodaerPosition"
        />
        


<div className="py-4 midWrapper">


{/* welcome sect start */}
<section className="py-5 welcome-row">
    <div className="container">
        <div className="row">
            <div className="col-md-6 text-center">
                <img src="images/welcome_img.jpg" alt="welcome to" className="img-fluid" />
            </div>
            <div className="col-md-6 d-flex align-items-center ">
                <div>
                <h5>Welcome to</h5>
                <h2>Angitia Life Care</h2>
                <p style={{fontSize: '1.2rem'}}>{abouthome}</p>
                {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor Lorem ipsum dolor sit amet consetetur sadipscing elitr Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                {/* {parse(''+welcomecontent)} */}

            </div>
            </div>
            
        </div>
        </div>
</section>
{/* welcome sect End */}


{/* our product start */}
<section className="py-5 dott_bg_left product-row">
    <div className="container mb-5">
        <div className="row">
            <div className="col-md-12">
                <header className="text-center mb-5 w-100 m-auto">
                    <h2>Our Products</h2>
                    <p>If "Angitia Lifecare" is a new development in the healthcare or life sciences industry, I would recommend looking for information from reliable sources such as their official website, press releases, or news articles for more details about their products, services, and innovations. </p>
                </header>
            </div>
        </div>

        <div className="row">
            <div className="col-md-4 col-sm-6">
                <figure>
                    <img src="images/medical.jpg" alt="welcome to" className="img-fluid" />
                </figure>
                <figcaption>
                    <a href="#" className="fw-bold">Medical</a>
                </figcaption>                    
            </div>
            <div className="col-md-4 col-sm-6">
                <figure>
                    <img src="images/oral_care.jpg" alt="welcome to" className="img-fluid" />
                </figure>
                <figcaption>
                    <a href="#" className="fw-bold"> Oral Care </a>
                </figcaption>                    
            </div>
            <div className="col-md-4 col-sm-6 m-auto">
                <figure>
                    <img src="images/separation_purification.jpg" alt="welcome to" className="img-fluid" />
                </figure>
                <figcaption>
                    <a href="#" className="fw-bold"> Separation & Purification </a>
                </figcaption>                    
            </div>
        </div>
        </div>
</section>
{/* our product End */}


{/* our testimonials start */}
<Testimonial /> 
{/* our testimonials End */}


{/* Blog start */}
 {/* <section className="py-5 blog-row">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <header className="text-center mb-5 w-50 m-auto">
                    <h2>Our Blog</h2>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor Lorem ipsum dolor sit amet consetetur sadipscing elitr</p>
                </header>
            </div>
        </div>

        <div className="row">
            <div className="col-md-4 col-sm-6">
                <div className="blog-box">
                <figure>
                    <img src="images/blog_img1.jpg" alt="welcome to" className="img-fluid" />
                </figure>
                <figcaption>
                    <p className="date-text m-0 text-muted"><i className="fa fa-calendar-o" aria-hidden="true"></i> February 1, 2021</p>
                    <h5><a href="#" className="fw-bold">Lorem ipsum dolor sit amet, consecte
                        tur adipiscing elit</a></h5>
                    <p className="text-muted">Lorem ipsum dolor sit amet, consetetur ipscing elitr, sed diam nonumy eirmod tempor Lorem ipsum dolor sit amet consetetur sadipscing elitr</p>
                </figcaption> 
            </div>                  
            </div>
            <div className="col-md-4 col-sm-6">
                <div className="blog-box">
                    <figure>
                        <img src="images/blog_img2.jpg" alt="welcome to" className="img-fluid" />
                    </figure>
                    <figcaption>
                        <p className="date-text m-0 text-muted"><i className="fa fa-calendar-o" aria-hidden="true"></i> February 1, 2021</p>
                        <h5><a href="#" className="fw-bold">Lorem ipsum dolor sit amet, consecte
                            tur adipiscing elit</a></h5>
                        <p className="text-muted">Lorem ipsum dolor sit amet, consetetur ipscing elitr, sed diam nonumy eirmod tempor Lorem ipsum dolor sit amet consetetur sadipscing elitr</p>
                    </figcaption> 
                </div>                
            </div>
            <div className="col-md-4 col-sm-6 m-auto">
                <div className="blog-box">
                    <figure>
                        <img src="images/blog_img3.jpg" alt="welcome to" className="img-fluid" />
                    </figure>
                    <figcaption>
                        <p className="date-text m-0 text-muted"><i className="fa fa-calendar-o" aria-hidden="true"></i> February 1, 2021</p>
                        <h5><a href="#" className="fw-bold">Lorem ipsum dolor sit amet, consecte
                            tur adipiscing elit</a></h5>
                        <p className="text-muted">Lorem ipsum dolor sit amet, consetetur ipscing elitr, sed diam nonumy eirmod tempor Lorem ipsum dolor sit amet consetetur sadipscing elitr</p>
                    </figcaption> 
                </div>                
            </div>
        </div>
      </div>
</section> */}

 {/* Blog End */}


</div>


        </>
    );
}

export default IndexContent;