import React, {useState, useEffect} from "react";

import LoadingBar from 'react-top-loading-bar';

import axios from "axios";
axios.defaults.baseURL='https://angitia.co.in/angitialara/api/';


function MemberContent() {
    const [progress,setProgress]=useState(0);

    const [directorimagearr, setDirectorimagearr] = useState([]);
    const [teamimagearr, setTeamimagearr] = useState([]);
    const [groupimage, setGroupimage] = useState();
    const [groupimagetitle, setGroupimagetitle] = useState("");

    async function getteam() {

        setProgress(100);

        var resp=await axios.get("/team");
        var data=resp.data;
        setDirectorimagearr(data.row);
        setTeamimagearr(data.obj);
        setGroupimage(data.group_image);
        setGroupimagetitle(data.group_title);       
    }

    let imgpath="https://angitia.co.in/angitialara/upload_team/";

    useEffect(() => {
        getteam();
    }, [])
   
            
    return(
        <>

    <LoadingBar
        color='#0e00d1'
        progress={progress}
        transitionTime={2000}
        loaderSpeed={1000}
        waitingTime={2000}
        onLoaderFinished={()=>setProgress(0)}
        className="lodaerPosition"
        />


<div className="py-4 midWrapper">
<section className="py-5 gallery-row">
    <div className="container">
        <div className='text-center'> 
            <h4>MANAGING DIRECTOR</h4>
        </div> 

        {directorimagearr.map((e)=>
            <div className="col-md-4" key={e.id}>
            <figure className="text-center">
                <img src={imgpath+e.photo} alt="" className="img-fluid" style={{width: '80%', height: '80%', borderRadius: '15%'}} />
                <figcaption className="text-center">
                    <h4>{e.name}</h4>
                    <p className='text-muted'>{e.designation}</p>
                </figcaption>                   
            </figure>
                    
        </div>
        )}

        <hr/>

        <div className='text-center'> 
            <h4>OUR MEMBERS</h4>
        </div> 

        <div className="row pt-4">


        {teamimagearr.map((e)=>
            <div className="col-md-4" key={e.id}>
            <figure className="text-center">
                <img src={imgpath+e.photo} alt="" className="img-fluid" style={{width: '80%', height: '80%', borderRadius: '15%'}} />
                <figcaption className="text-center">
                    <h4>{e.name}</h4>
                    <p className='text-muted'>{e.designation}</p>
                </figcaption>                   
            </figure>
                    
        </div>
            
        )}



            {/* <div className="col-md-4">
                <figure>
                    <img src="images/team-1.jpg" alt="" className="img-fluid" />
                </figure>
                <figcaption>
                    <h4>KAUSHIK MAITRA</h4>
                    <p className='text-muted'>Frontend & Backend Developer</p>
                </figcaption>
               
            </div>
            <div className="col-md-4">
            <figure>
                    <img src="images/team-2.jpg" alt="" className="img-fluid" />
                </figure>
                <figcaption>
                    <h4>Ashok Mahato</h4>
                    <p className='text-muted'>Frontend & Backend Developer</p>
                </figcaption>
            </div>
            <div className="col-md-4">
            <figure>
                    <img src="images/team-3.jpg" alt="" className="img-fluid" />
                </figure>
                <figcaption>
                    <h4>Rajesh Mishra</h4>
                    <p className='text-muted'>Frontend & Backend Developer</p>
                </figcaption>
            </div>           */}
            
        </div>
        <hr />
        <div className="row pt-5">
            <div className="col-md-12">
            <figure className="text-center">
            <div className='text-center pb-4'> 
                <h4>ANGITIA TEAM</h4>
            </div> 
            
            {/* <img src="images/team.jpg" alt="" className="img-fluid" /> */}
            <img src= {imgpath+groupimage} alt="" className="img-fluid" style={{width: '90%', height: '90%', borderRadius: '5%'}} />  
                      

            </figure>
            
            {/* <figcaption className='text-center'> 
                    <h4>{groupimagetitle}</h4>                    
            </figcaption> */}
            </div>               
            
        </div>
       




        </div>
</section>







</div>

        </>
    );
}

export default MemberContent;