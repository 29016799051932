// import React, { useState } from 'react';
// import {Link} from 'react-router-dom';

import React, {useState, useEffect} from "react";

import axios from "axios";
import {Link} from 'react-router-dom';

axios.defaults.baseURL='https://angitia.co.in/angitialara/api/';


function Navbar() {

  const [catlisting, setCatlisting] = useState([]);

  async function getparentcat() {
      var resp=await axios.get("/getparentcat");
      var data=resp.data;
      setCatlisting(data);
  
   }

   
  useEffect(() => {

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    getparentcat();
    
  }, [])


    return(
        <>        
       
     <nav className="navbar navbar-expand-lg navbar-light bg-blank sticky-top">
            <div className="container-fluid">
              <Link className="navbar-brand" to="/"><img src="../../images/logo.png" alt="" /></Link>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fa fa-bars" aria-hidden="true"></i>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                  </li>
                  <li className="nav-item"><Link className="nav-link" to="/about-us">About</Link></li>
                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Our Products
                    </Link>


                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    {catlisting.map((e)=>
                      <li key={e.cat_id}><Link className="dropdown-item" to={'/products/'+e.cat_name+ '/'+e.cat_id}>{e.cat_name}</Link></li>
                    )}
                      

                    </ul>


                  </li>
                  {/* <li className="nav-item"><Link className="nav-link" to="/blog">Blog</Link></li> */}
                  <li className="nav-item"><Link className="nav-link" to="/gallery">Gallery</Link></li>
                  <li className="nav-item"><Link className="nav-link" to="/members">Members</Link></li>
                  <li className="nav-item"><Link className="nav-link btn-primary text-light rounded px-3" to="/contact-us">Contact Us</Link></li>
                </ul>
                
              </div>
            </div>
          </nav>

       
        </>
    );
}

export default Navbar;