import React  from 'react';

import ContactContent from "../content/ContactContent";

import Navbar from "../inc/Navbar";
import BannerInner from "../inc/BannerInner";
import Footer from "../inc/Footer";

function Gallery() {
    return(
        <>
        <Navbar />
        <BannerInner />
        <ContactContent />
        <Footer />        
        </>
    );
}

export default Gallery;