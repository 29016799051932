
import React, {useState, useEffect} from "react";
import {useParams, Link} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';

import axios from "axios";
import parse from 'html-react-parser';
import {useNavigate} from "react-router-dom";

axios.defaults.baseURL='https://angitia.co.in/angitialara/api/';

function ProductDetailsContent() {
    const [progress,setProgress]=useState(0);

    let navigate=useNavigate();

    const rootimg="https://angitia.co.in/angitialara/upload_product/";
 
    let [info,setInfo]=useState("");
    let prmid=useParams();
    
    async function getproductdetails() {
        setProgress(100);

        var fd = new FormData();
        fd.append("id", prmid.id);
        var resp=await axios.post("/getproductdetails",fd);
        var data=resp.data;
        setInfo(data);

        console.log(data);      
     }

 


     
     
     
    useEffect(() => {
        getproductdetails();
        // getchildlist();
    }, [])

    return(
        <>

        <LoadingBar
            color='#0e00d1'
            progress={progress}
            transitionTime={2000}
            loaderSpeed={1000}
            waitingTime={2000}
            onLoaderFinished={()=>setProgress(0)}
            className="lodaerPosition"
        />

<div className="py-4 midWrapper">



<section className="py-5 welcome-row">
    <div className="container">
        <div className="row">


        <div className="col-md-12">
            <div className='row pb-3'>
                <div className="col-md-5">
                    <img src={rootimg+info.product_image} class="img-fluid border" />                        
                </div>
                <div className="col-md-7">
                    <h5 className='py-2'>{info.product_name}</h5>
                    <div>{info.product_short_desc}</div>
                </div>

                <div className="col-md-12 text-center">

                    <button className='btn btn-info' onClick={()=>{
                        let path=`/products/`+info.cat_name+'/'+info.cat_parent_id;
                        navigate(path);
                        // history.back();
                    }}><i className='fa fa-left-arrow'></i> Back</button>
                </div>
               
            </div>
            
            
       
        </div>

          
            
        </div>
       
    



        </div>
</section>









</div>

        </>
    );
}

export default ProductDetailsContent;