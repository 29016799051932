// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import React from 'react';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import Home from './layout/Home';
import About from './layout/About';
import Blog from './layout/Blog';
import Gallery from './layout/Gallery';
import Members from './layout/Members';
import Contact from './layout/Contact';

import Products from './layout/Products';
import ProductDetails from './layout/ProductDetails';

import './App.css';

function App() {
  return (
    <>
    <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Home />} /> 
          {/* <Route path={`${process.env.PUBLIC_URL}/`} component={Home} /> */}
          {/* ${process.env.PUBLIC_URL} */}
          <Route exact path='/about-us' element={<About />} />
          {/* <Route exact path='/blog' element={<Blog />} /> */}
          <Route exact path='/gallery' element={<Gallery />} />
          <Route exact path='/members' element={<Members />} />
          <Route exact path='/contact-us' element={<Contact />} />
          <Route exact path='/products/:catname/:parentid' element={<Products />} />
          <Route exact path='/productdetails/:productname/:id' element={<ProductDetails />} />

        </Routes>
        </BrowserRouter>
    </>
  );
}

export default App;
