import React, { Component, useState, useEffect }  from 'react';
// import {useParams} from "react-router-dom";
// import React, {Component, useState, useEffect} from "react";

function BannerInner2({dataparenttochildtitle}) {
// function BannerInner  () {

    // let prmid=useParams();

    const [pagetitle, setPagetitle] = useState("");

    // if (dataparenttochildtitle==1) {
    //     setPagetitle();
    // } else if (dataparenttochildtitle==2) {
    //     setPagetitle();
    // } else if (dataparenttochildtitle==3) {
    //     setPagetitle();
    // }

    useEffect(() => {

    }, [])

  return(
      <>
      
        <header className="bg_header headerspace header_inner">
 

            <div className="banner_text_wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner_text_area">

                                <h2>{dataparenttochildtitle} </h2>
                                {/* <p><a href="#">Home</a> : About Us </p> */}
                            
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div> 
        
            
        </header>

      </>
  );
}

export default BannerInner2;