import React  from 'react';

import MemberContent from "../content/MemberContent";

import Navbar from "../inc/Navbar";
import BannerInner from "../inc/BannerInner";
import Footer from "../inc/Footer";

function Member() {
    return(
        <>
        <Navbar />
        <BannerInner />
        <MemberContent />
        <Footer />        
        </>
    );
}

export default Member;