
import React  from 'react';
import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from "axios";
import validator from 'validator';

import LoadingBar from 'react-top-loading-bar';

// axios.defaults.baseURL='https://angitia.co.in/angitialara/api/';

function ContactContent() {

    const [progress,setProgress]=useState(0);

    const h4txt={
        marginBottom: '30px',
        textAlign: 'center'
    };

    const errtxt={
        fontSize: 'small'
    };

   

    let [name,setName]=useState("");
    let [isname,setIsname]=useState(false);

    let [email,setEmail]=useState("");
    let [isemail,setIsemail]=useState(false);

    let [phone,setPhone]=useState("");
    let [isphone,setIsphone]=useState(false);

    let [subject,setSubject]=useState("");
    let [issubject,setIssubject]=useState(false);

    let [content,setContent]=useState("");
    let [iscontent,setIscontent]=useState(false);

    let [isbtn,setIsbtn]=useState(false);

    let [msg,setMsg]=useState("");
    let [msgcolor,setMsgcolor]=useState("");
    let [isalert,setIsalert]=useState(false);

    async function sendmessage() {
        
        setProgress(100);

        var flag=1;

        if (name.length==0 || name.trim()=="") {
            setIsname(true);
            flag=0;
        }
        if (!validator.isEmail(email)) {
            setIsemail(true);
            flag=0;
        }
        if (phone.length<10 || phone.trim()=="") {
            setIsphone(true);
            flag=0;
        }
        if (subject.length==0 || subject.trim()=="") {
            setIssubject(true);
            flag=0;
        }
        if (content.length==0 || content.trim()=="") {
            setIscontent(true);
            flag=0;
        }

        if (flag==1) {
            var fd=new FormData();
            fd.append("name",name);
            fd.append("email",email);
            fd.append("phone",phone);
            fd.append("subject",subject);
            fd.append("content",content);

            var resp=await axios.post("https://angitia.co.in/angitialara/api/sendcontact",fd);
            var data=resp.data;

            setMsg(data.msg);
            setIsalert(data.sendmsg);
            setMsgcolor(data.msgcolor);

            setIsbtn(true);            
        }
    }

    let [contact,setContact]=useState("");
    async function getcontactdetails() {
        var resp=await axios.get("https://angitia.co.in/angitialara/api/getcontactdetails");
        var data=resp.data;

        setContact(data);
    }

    useEffect(()=>{
        getcontactdetails();
    },[]);

    return(
        <>
     <LoadingBar
        color='#0e00d1'
        progress={progress}
        transitionTime={2000}
        loaderSpeed={1000}
        waitingTime={2000}
        onLoaderFinished={()=>setProgress(0)}
        className="lodaerPosition"
        />

<div className="py-4 midWrapper">
<section className="py-5 contact-row">
    <div className="container">
        <div className="row">
            <div className="col-md-7">
                <div className='row'>
                    <div className="col-md-12">

                    <div className='card mb-3'>
                        <div class="card-body text-center">
                             <div class="info-box">
                                 <span className='border radius'>
                                 <i class="fa fa-map-marker" aria-hidden="true"></i>
                                 </span>
                             
                            <h3>Angitia Lifecare Pvt Ltd</h3>
                            <p className='fs-5'>{contact.cms_page_addr}</p>
                        </div>
                        </div>
                        </div>


                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-7">
                    <div className='card mb-3'>
                        <div class="card-body text-center">
                             <div class="info-box">
                                 <span className='border radius'>
                                 <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                 </span>
                             
                            <h3>Email Us</h3>
                            <p className='fs-5'>{contact.cms_page_email}</p>
                        </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                    <div className='card mb-3'>
                        <div class="card-body text-center">
                             <div class="info-box">
                                 <span className='border radius'>
                                 <i class="fa fa-phone" aria-hidden="true"></i>
                                 </span>
                             
                            <h3>Call Us</h3>
                            <p className='fs-5'><a href={"tel:"+contact.cms_page_phone}>{contact.cms_page_phone}</a></p>
                        </div>
                        </div>
                        </div>
                   
                    </div>
                </div>
            </div>
            {/* <div className="col-md-6">
                    <h4 className='text-center' style={{marginBottom: '20px'}}>Contact Us</h4>
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <input type="text" class="form-control" placeholder="Your Name" />
                        </div>
                        <div class="col-md-6 form-group mt-3 mt-md-0">
                            <input type="email" class="form-control" placeholder="Your Email" />
                        </div>
                    </div>
                    <div class="form-group mt-3"><input type="tel" minlength="10" maxlength="10" class="form-control" placeholder="Your Phone" /></div> 
                    <div class="form-group mt-3"><input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" /></div>
                    <div class="form-group mt-3"><textarea class="form-control" rows="5" placeholder="Message"></textarea></div>
                    <div class="text-center form-group mt-3">
                        <button class="btn btn-primary w-100">Send Message</button>
                    </div>
            </div> */}

            <div className="col-md-5 p-4" style={{border: '1px solid #ff9fff'}}>
                <div className="container_">
                    <h4 style={h4txt}>Contact Us</h4>
                    <div className='text-center'>{isalert?<div className={msgcolor} style={{margin: 30}}>{msg}</div>:''}</div>
                    <div className="row">
                        <div className="col-md-6 form-group_">
                        <input type="text" className="form-control form-control-lg" placeholder="Your Name" onChange={(ev)=>{
                            setName(ev.target.value);
                            if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                                setIsname(false);
                            }
                        }} />
                        {isname?<div className="text text-danger" style={errtxt}>contact name required</div>:''}
                        </div>
                        <div className="col-md-6 form-group mt-4 mt-md-0">
                        <input type="email" className="form-control form-control-lg" placeholder="Your Email" onChange={(ev)=>{
                            setEmail(ev.target.value);
                            if (validator.isEmail(email)) {
                                setIsemail(false);
                            }
                        }} />
                        {isemail?<div className="text text-danger" style={errtxt}>contact email required</div>:''}
                        </div>
                    </div>
                    <div className="form-group mt-4">
                        <input type="tel" minLength={10} maxLength={10} className="form-control form-control-lg" placeholder="Your Phone" onChange={(ev)=>{
                            setPhone(ev.target.value);
                            if (ev.target.value.length==10 && ev.target.value.trim()!="") {
                                setIsphone(false);
                            }
                        }} />
                        {isphone?<div className="text text-danger" style={errtxt}>contact phone required</div>:''}
                    </div>
                    <div className="form-group mt-4">
                        <input type="text" className="form-control form-control-lg" name="subject" id="subject" placeholder="Subject" onChange={(ev)=>{
                            setSubject(ev.target.value);
                            if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                                setIssubject(false);
                            }
                        }} />
                        {issubject?<div className="text text-danger" style={errtxt}>subject required</div>:''}
                    </div>
                    <div className="form-group mt-4">
                        <textarea className="form-control form-control-lg" rows="5" placeholder="Message" onChange={(ev)=>{
                            setContent(ev.target.value);
                            if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                                setIscontent(false);
                            }
                        }} />
                        {iscontent?<div className="text text-danger" style={errtxt}>message required</div>:''}
                    </div>
 
                    <div className="form-group mt-4">
                        <div className="text-center"><button className="btn btn-primary btn-lg" onClick={sendmessage} disabled={isbtn} >Send Message</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="map-row">
    <div style={{overflow:'hidden',width:'100%',height:'510px'}}>
      <div id="google-maps-display" style={{height:'100%', width:'100%'}}>
        <iframe style={{height:'100%',width:'100%',border:'0'}} frameborder="0" src={"https://www.google.com/maps/embed/v1/place?q=Angitia lifecare, " +contact.cms_page_addr+ "&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"}></iframe>
      </div>
    </div>
</section>

</div>



        </>
    );
}

export default ContactContent;