import React  from 'react';

import GalleryContent from "../content/GalleryContent";

import Navbar from "../inc/Navbar";
import BannerInner from "../inc/BannerInner";
import Footer from "../inc/Footer";

function Gallery() {
    return(
        <>
        <Navbar />
        <BannerInner />
        <GalleryContent />
        <Footer />        
        </>
    );
}

export default Gallery;