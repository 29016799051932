import React, {useState, useEffect} from "react";

import ProductsContent from "../content/ProductsContent";

import Navbar from "../inc/Navbar";
import BannerInner2 from "../inc/BannerInner2";
import Footer from "../inc/Footer";

import {useParams, Link} from "react-router-dom";

function Products() {

    let prmid=useParams();
    


    return(
        <>
        <Navbar />
        <BannerInner2 dataparenttochildtitle={prmid.catname} />
        <ProductsContent />
        <Footer />        
        </>
    );
}

export default Products;