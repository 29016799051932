import React, {useState, useEffect} from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import axios from "axios";
axios.defaults.baseURL='https://angitia.co.in/angitialara/api/';

function Testimonials() {

    const [testimonialarr, setTestimonialarr] = useState([]);

    async function gettestimonial() {
        var resp=await axios.get("/testimonial");
        var data=resp.data;
        setTestimonialarr(data.obj);
     }

     let imgpath="https://angitia.co.in/angitialara/upload_testimonial/";

    useEffect(() => {
        gettestimonial();
    }, [])



    const testimonial_carousel = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 2
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };



    return(
      <>

      {/* our testimonials start */}
<section className="py-5 testimonials_bg testimonials-row">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <header className="text-center mb-5 w-50 m-auto">
                    <h2>Testimonials</h2>
                    <p>Feel free to adjust the testimonial as needed to fit your requirements!</p>
                </header>
            </div>
        </div>

        <div className="row">
            <div className="col-md-11 offse-md-1 m-auto">
            
            <Carousel responsive={testimonial_carousel} arrows={false} showDots={true} autoPlay={true} autoPlaySpeed={1500}>


            {testimonialarr.map((e)=>

                <div className="item"  key={e.id}>
                <div className="testimonial_box">
                    <h3>
                        <img src= {imgpath+e.image} alt="" className="testimonial_img" />                        
                        {e.name} -<span> {e.designation}</span></h3>
                    <p>{e.content}</p>
                </div>
                </div>


        //     <div key={e.slider_id}>
        //     <img src= {imgpath+e.slider_image} alt="" />
        //     <div className="banner_text_wrap">
        //           <div className="container">
        //               <div className="row">
        //                   <div className="col-md-12">
        //                       <div className="banner_text_area">
        //                           <h2>{e.slider_name}</h2>
        //                           <p>{e.slider_desc}</p>
        //                           <p className="btn-know-more"><a href={e.slider_link} className="btn btn-primary rounded btn-lg">Know more</a></p>
        //                       </div>
        //                   </div>
        //               </div>
        //             </div>
        //     </div>  
        //   </div>
        )}

{/* 
            <div className="item">
                <div className="testimonial_box">
                    <h3>
                        <img src="images/ashok.jpg" alt=""  className="testimonial_img" />
                        Ashok Mahato -<span>mywebsite.com</span></h3>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusame</p>
                </div>
            </div>

            <div className="item">
                <div className="testimonial_box">
                    <h3>
                        <img src="images/ashok.jpg" alt=""  className="testimonial_img" />
                        Ashok Mahato -<span>mywebsite.com</span></h3>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusame</p>
                </div>
                </div>

                <div className="item">
                <div className="testimonial_box">
                    <h3>
                        <img src="images/ashok.jpg" alt=""  className="testimonial_img" />
                        Ashok Mahato -<span>mywebsite.com</span></h3>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusame</p>
                </div>
                </div>

                <div className="item">
                <div className="testimonial_box">
                    <h3>
                        <img src="images/ashok.jpg" alt=""  className="testimonial_img" />
                        Ashok Mahato -<span>mywebsite.com</span></h3>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusame</p>
                </div>
            </div>

            <div className="item">
                <div className="testimonial_box">
                    <h3>
                        <img src="images/ashok.jpg" alt=""  className="testimonial_img" />
                        Ashok Mahato -<span>mywebsite.com</span></h3>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusame</p>
                </div>
                </div> */}

            
      










</Carousel>
            </div>               
        </div>
      </div>
</section>
{/* our testimonials End */}





      </>
  );
}

export default Testimonials;