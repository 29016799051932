import React from 'react';

function Footer() {
    return(
        <>
        
        <footer className="ft_bg pt-3">
<section className="py-5 ft-link-row">
    <div className="container">
        <div className="row">
            <div className="col-md-4">
                <h3 className="pb-3"><img src="../../images/logo.png" alt="" /></h3>
                <p>ANGITIA LIFE CARE PRIVATE LIMITED, is a private company. ANGITIA LIFE CARE PRIVATE LIMITED Corporate Identification Number (CIN) is U51909WB2020PTC240907 and its registration No. is 240907.</p>
            </div>
            <div className="col-md-3 ft_link">
                <h3 className="pb-3">Links</h3>
                <ul>
                    <li><a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Home</a></li>
                    <li><a href="/about-us"><i className="fa fa-angle-right" aria-hidden="true"></i> About</a></li>
                    {/* <li><a href="#"><i className="fa fa-angle-right" aria-hidden="true"></i> Our Products</a></li> */}
                    <li><a href="/gallery"><i className="fa fa-angle-right" aria-hidden="true"></i> Gallery</a></li>
                    <li><a href="/members"><i className="fa fa-angle-right" aria-hidden="true"></i> Members</a></li>
                    <li><a href="/contact-us"><i className="fa fa-angle-right" aria-hidden="true"></i> Contact Us</a></li>
                </ul>
            </div>
            <div className="col-md-5">
                <h3 className="pb-3">Contact Us</h3>
                <div className="ft_contact_form">
                    
                        <div className="mb-3">                          
                          <input type="text" className="form-control" placeholder="Your name" />                          
                        </div>
                        <div className="mb-3">                          
                            <input type="email" className="form-control" placeholder="Email address" />                          
                          </div>
                          <div className="mb-3">                          
                            <textarea className="form-control" placeholder="Your Coment"  rows="3"></textarea>                          
                          </div>
                        <button type="submit" className="btn btn-primary w-100 text-uppercase">Submit</button>
                      
                </div>

            </div>
        </div>  
      </div>
</section>

<section className="py-3 ft-copyright-row">
    <div className="container">
        <div className="row">
            <div className="col-md-7 pt-3">Copyright © 2022 Angitia Lifecare Pvt Ltd.  All rights reserved.  </div>
            <div className="col-md-5 text-end ft-social-icon">
                <a href="#" target="_blank"><i className="fa  fa-youtube-play"></i></a>
                <a href="#" target="_blank"><i className="fa fa-google-plus-square"></i></a>
                <a href="#" target="_blank"><i className="fa fa-linkedin-square"></i></a>
                <a href="#" target="_blank"><i className="fa fa-pinterest-square"></i></a>
                <a href="#" target="_blank"><i className="fa fa-twitter-square"></i></a>                
                <a href="#" target="_blank"><i className="fa fa-facebook-official"></i></a>               
            </div>
        </div>
    </div>
</section>

    
</footer>

        </>
    );
}

export default Footer;