
import React, {useState, useEffect} from "react";
import Testimonial from '../../src/inc/Testimonials';
import axios from "axios";
import parse from 'html-react-parser';

import LoadingBar from 'react-top-loading-bar';


axios.defaults.baseURL='https://angitia.co.in/angitialara/api/';




function AboutContent() {

    const [progress,setProgress]=useState(0);

    const [pagetitle, setPagetitle] = useState("");

    const data = "Hello Everyone";
   
    const [aboutcontent, setAboutcontent] = useState("");

    async function getaboutcontent() {
        setProgress(100);

        var resp=await axios.get("/about");
        var data=resp.data;
        setAboutcontent(data.about_content);       
     }

    

     
     
    useEffect(() => {
        getaboutcontent();
    }, [])


    return(
        <>

    <LoadingBar
        color='#0e00d1'
        progress={progress}
        transitionTime={2000}
        loaderSpeed={1000}
        waitingTime={2000}
        onLoaderFinished={()=>setProgress(0)}
        className="lodaerPosition"
        />

<div className="py-4 midWrapper">



<section className="py-5 welcome-row">
    <div className="container">

    

            {/* {parse(aboutcontent)} */}
            {parse(''+aboutcontent)}
    
        {/* <div className="row">
            <div className="col-md-5 text-center">
                <img src="images/OurVision.jpg" alt="welcome to" className="img-fluid" />
            </div>
            <div className="col-md-7 d-flex align-items-center ">
                <div>                
                <h2>Our Vision</h2>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor Lorem ipsum dolor sit amet consetetur sadipscing elitr Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
            </div>
            </div>
            
        </div>
        <p className='mt-4'><br /></p>
        <div className="row">            
            <div className="col-md-7 d-flex align-items-center ">
                <div>                
                <h2>Our Vision</h2>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor Lorem ipsum dolor sit amet consetetur sadipscing elitr Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
            </div>
            </div>
            <div className="col-md-5 text-center">
                <img src="images/vision.png" alt="welcome to" className="img-fluid" />
            </div>
            
        </div> */}



        </div>
</section>


{/* our testimonials start */}
<Testimonial /> 
{/* our testimonials End */}






</div>

        </>
    );
}

export default AboutContent;