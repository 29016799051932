import React, {useState, useEffect} from "react";

import ProductDetailsContent from "../content/ProductDetailsContent";

import Navbar from "../inc/Navbar";
import BannerInner2 from "../inc/BannerInner2";
import Footer from "../inc/Footer";

import {useParams, Link} from "react-router-dom";
import axios from "axios";

function ProductDetails() {

    let prmid=useParams();
    
    return(
        <>
        <Navbar />
        <BannerInner2  dataparenttochildtitle={prmid.productname}/>
        <ProductDetailsContent />
        <Footer />        
        </>
    );
}

export default ProductDetails;